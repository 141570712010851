<template>
  <Table :columns="columns" :data="computedData"></Table>
</template>

<script>
import { map, mean, isNumber, chain } from 'lodash';

export default {
  name: `Score`,
  props: {
    displayScores: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    columns: [
      {
        title: 'Nom',
        key: 'name',
      },
      {
        title: 'Difficulté',
        key: 'difficulty',
      },
      {
        title: 'Temps',
        key: 'time',
      },
    ],
    data: [],
  }),
  methods: {
    getScore(score) {
      if (this.displayScores) {
        return score;
      } else if (!score) {
        return `Doit voter`;
      }
    },
    median(values) {
      if (values.length === 0 || !this.displayScores) return 0;

      values.sort((a,b) => a - b);

      const half = Math.floor(values.length / 2);

      if (values.length % 2)
        return values[half];

      return (values[half - 1] + values[half]) / 2.0;
    }
  },
  computed: {
    computedData() {
      const scores = chain(this.data).map((score) => ({
        name: score.name,
        difficulty: this.getScore(score?.votes?.difficulty),
        time: this.getScore(score?.votes?.time),
      }))
        .sortBy(d => isNumber(d.difficulty) ? d.difficulty : 101)
        .value();

      const times = map(scores, `time`).filter(t => isNumber(t));
      const difficulties = map(scores, `difficulty`).filter(t => isNumber(t));

      const avg = {
        name: `Moyenne`,
        time: mean(times) || 0,
        difficulty: mean(difficulties) || 0,
      };
      const median = {
        name: `Mediane`,
        time: this.median(times) || 0,
        difficulty: this.median(difficulties) || 0,
      };

      scores.push(avg, median);

      return scores;
    },
  },
  sockets: {
    setUsers(data) {
      this.data = data;
    },
  },
};
</script>

<style>
.ivu-table-header * {
  @apply text-gray-300 !important;
  background-color: #37393f !important;
}
.ivu-table-row * {
  @apply bg-gray-700 text-gray-300 !important;
  background-color: #2f3136 !important;
}
.ivu-table-wrapper * {
  @apply border-gray-900 overflow-hidden !important;
}
.ivu-table {
  @apply border !important;
  background-color: #37393f !important;
}
</style>
