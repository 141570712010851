<template>
  <Layout id="app" class="min-h-screen">
    <Content class="h-full center">
      <Poker />
    </Content>
  </Layout>
</template>

<script>

import Poker from "./components/Poker";
export default {
  name: `app`,
  components: {
    Poker,
  },
};
</script>

<style>
#app {
  @apply text-gray-50 !important;
  background-color: #37393f !important;
}

.ivu-row {
  gap: 0.5rem;
}

#app > div {
  @apply w-full md:w-8/12 mx-auto my-0;
}
</style>
